import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { VideoSDK } from "@videosdk.live/js-sdk";
import { Meeting } from "@videosdk.live/js-sdk/meeting";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AdminMeetingService {

    API_BASE_URL: string = environment.API_BASE_URL || "";
    AUTH_URL: string = environment.AUTH_URL || "";
    Auth_Token: string = environment.Auth_Token || null;

    IsMeetStart: Boolean = false;
    StageMeeting: MeetingInfo = { MeetingId: null, MeetingInstance: null, Participants: [], AllParticipants: [] };
    BackStageMeeting: MeetingInfo = { MeetingId: null, MeetingInstance: null, Participants: [], AllParticipants: [] };

    micOn: any;
    micOff: any;
    videoCamOff: any;
    videoCamOn: any;
    videoContainer: any;
    micEnable = false;
    webCamEnable = false;
    leaveCall: any = null;
    endCall: any = null;

    joinPageVideoStream = null;
    joinPageWebcam: any;

    constructor(
        private router: Router,
    ) { }

    //create New Meeting
    createMeeting = async (): Promise<string> => {
        if (!!!this.Auth_Token) await this.generateVideoSDKToken();

        return new Promise(async (resolve) => {
            const url = `${this.API_BASE_URL}/api/meetings`;
            const options = {
                method: "POST",
                headers: { Authorization: this.Auth_Token, "Content-Type": "application/json" },
            };
            const { meetingId } = await fetch(url, options).then((response) => response.json()).catch((error) => {
                console.log("ERROR in createNewMeeting", error);
                return {}
            });
            if (meetingId) {
                return resolve(meetingId)
            }
            return resolve(null)
        });
    }

    //validateMeeting Meeting
    validateMeeting = async (meetId: string): Promise<string> => {
        if (!!!this.Auth_Token) await this.generateVideoSDKToken();

        return new Promise(async (resolve) => {
            const url = `${environment.API_BASE_URL}/api/meetings/${meetId}`;
            const options = {
                method: "POST",
                headers: { Authorization: this.Auth_Token, "Content-Type": "application/json" },
            };
            const { meetingId } = await fetch(url, options).then((response) => response.json()).catch((error) => {
                console.log("ERROR in validateMeeting", error);
                return {}
            });
            if (meetingId) {
                // await this.setVideoMediaStream();
                return resolve(meetingId)
            }
            return resolve(null)
        });
    }

    //generate Auth token to join meeting
    generateVideoSDKToken = async () => {
        if (this.AUTH_URL != "") {
            return new Promise(async (resolve) => {
                this.Auth_Token = null;
                await window.fetch(this.AUTH_URL + "/generateJWTToken").then(async (response) => {
                    try {
                        const { token } = await response.json();
                        this.Auth_Token = token || null;
                        return resolve(true);
                    } catch { }
                }).catch(async (e) => {
                    console.log("ERROR in generationVideoSDKToken", e);
                    return resolve(true);
                });
            });
        } else {
            alert("Check Your configuration once");
            window.location.href = "/";
        }
    }

    //start Meeting and Join
    startMeeting = async (meetingId: string, joinInfo: JoinInfo): Promise<any> => {
        return new Promise(async (resolve) => {
            try {
                const isStageMeeting = joinInfo.MeetingType === "Stage";

                //Set Meeting Id
                isStageMeeting ? this.StageMeeting.MeetingId = meetingId : this.BackStageMeeting.MeetingId = meetingId;

                // Meeting config
                VideoSDK.config(this.Auth_Token);

                //Video Track
                let customVideoTrack = await VideoSDK.createCameraVideoTrack({
                    optimizationMode: "motion",
                    encoderConfig: "h540p_w960p",
                    facingMode: "environment",
                });

                //Audia Track
                let customAudioTrack = await VideoSDK.createMicrophoneAudioTrack({
                    encoderConfig: "high_quality",
                    noiseConfig: {
                        echoCancellation: true,
                        autoGainControl: true,
                        noiseSuppression: true,
                    },
                });

                // Meeting Init
                const MeetingInstance = VideoSDK.initMeeting({
                    meetingId: meetingId,
                    token: this.Auth_Token,
                    name: JSON.stringify(joinInfo),
                    micEnabled: false,
                    webcamEnabled: false,
                    maxResolution: "hd",
                    customCameraVideoTrack: joinInfo.Role === SYS_Role.StageUser ? customVideoTrack : null,
                    customMicrophoneAudioTrack: joinInfo.Role === SYS_Role.StageUser ? customAudioTrack : null,
                    multiStream: true,

                });


                MeetingInstance.join();

                await this.waitUntil(2000);

                //Set Meeting Instance
                isStageMeeting ? this.StageMeeting.MeetingInstance = MeetingInstance : this.BackStageMeeting.MeetingInstance = MeetingInstance;

                await this.subScribeEvents(isStageMeeting ? this.StageMeeting : this.BackStageMeeting, joinInfo);

                
                this.addDOMClicks(isStageMeeting ? this.StageMeeting : this.BackStageMeeting);
            } catch (err) {
                console.log("Error in Start Meeting", err.message)
            }
            return resolve(true);
        })
    }

    subScribeEvents = async (currentMeet: MeetingInfo, joinInfo: JoinInfo): Promise<any> => {
        return new Promise(async (resolve) => {

            let AudioElement = null;
            if (joinInfo.Role === SYS_Role.StageUser)
                AudioElement = this.createLocalParticipantAudioVideo(currentMeet.MeetingInstance.localParticipant.id)

            // Setting local participant stream ON
            currentMeet.MeetingInstance.localParticipant.on("stream-enabled", (stream) => {
                this.setTrack(
                    stream,
                    AudioElement,
                    currentMeet.MeetingInstance.localParticipant,
                    true
                );
            });

            // Setting local participant stream OFF
            currentMeet.MeetingInstance.localParticipant.on("stream-disabled", (stream) => {
                if (stream.kind == "video") {
                    this.videoCamOn.style.display = "none";
                    this.videoCamOff.style.display = "inline-block";
                }
                if (stream.kind == "audio") {
                    this.micOn.style.display = "none";
                    this.micOff.style.display = "inline-block";
                }
            });

            currentMeet.MeetingInstance.on("meeting-joined", async (participant) => {
                this.IsMeetStart = true;
            });

            //subscribe event to detect when participant join in call
            currentMeet.MeetingInstance.on("participant-joined", async (participant) => {
                const joinInfo: JoinInfo = JSON.parse(participant.displayName);
                console.log("participant-joined", participant.id, joinInfo);
                currentMeet.AllParticipants.push({
                    pId: participant.id,
                    displayName: joinInfo.DisplayName,
                    meetId: currentMeet.MeetingId,
                    role: joinInfo.Role,
                    type: joinInfo.MeetingType
                });
                currentMeet.Participants = await this.getParticipants(currentMeet.AllParticipants);

                if (joinInfo.Role === SYS_Role.StageUser) {
                    setTimeout(() => {
                        const VideoElement = this.createVideoElement(participant.id);
                        if (this.videoContainer)
                            this.videoContainer.appendChild(VideoElement);
                        else if (document.getElementById('user-' + participant.id)) {
                            document.getElementById('user-' + participant.id).appendChild(VideoElement)
                        }

                        let AudioVideoElement = this.createAudioElement(participant.id);
                        participant.on("stream-enabled", (stream) => {
                            console.log("participant stream-enabled")
                            this.setTrack(
                                stream,
                                AudioVideoElement,
                                participant,
                                false
                            );
                        });
                    }, 1000);
                }
            });

            //subscribe event to detect when participant left in call
            currentMeet.MeetingInstance.on("participant-left", async (participant) => {
                console.log("participant-left", participant.id);

                let vElement = document.getElementById(`v-${participant.id}`);
                if (vElement) {
                    vElement.parentNode.removeChild(vElement);
                }

                let vFElement = document.getElementById(`vf-${participant.id}`);
                if (vElement) {
                    vFElement.remove();
                }

                let aElement = document.getElementById(`a-${participant.id}`);
                if (aElement) {
                    aElement.parentNode.removeChild(aElement);
                    //remove it from participant list participantId;
                    document.getElementById(`p-${participant.id}`)?.remove();
                }

                let userAdminPageElement = document.getElementById(`user-${participant.id}`);
                if (userAdminPageElement) {
                    userAdminPageElement.remove();
                }

                //remove/Leave Admin 
                const objP = currentMeet.AllParticipants.find((x) => x.pId === participant.id);
                if (objP?.role === SYS_Role.Admin) {
                    currentMeet.MeetingInstance.end();
                }

                currentMeet.AllParticipants = currentMeet.AllParticipants?.filter((x) => { return (x.pId !== participant.id) });
                currentMeet.Participants = await this.getParticipants(currentMeet.AllParticipants);
            });

            //
            currentMeet.MeetingInstance.on("switch-meeting", ({ meetingId, payload, token }) => {
                currentMeet.MeetingInstance.leave();
                const payloadInfo: JoinInfo = JSON.parse(payload);
                this.router.navigate([`backStage-meet/${meetingId}/${payloadInfo.DisplayName}/${payloadInfo.MeetingType}`])
            });

            return resolve(true)
        })
    }

    addDOMClicks = async (currentMeet: MeetingInfo) => {
        this.micOn?.addEventListener("click", () => {
            currentMeet.MeetingInstance.muteMic();
        });

        this.micOff?.addEventListener("click", () => {
            currentMeet.MeetingInstance.unmuteMic();
        });

        this.videoCamOn?.addEventListener("click", async () => {
            currentMeet.MeetingInstance.disableWebcam();
        });

        this.videoCamOff?.addEventListener("click", async () => {
            currentMeet.MeetingInstance.enableWebcam();
        });

        this.leaveCall?.addEventListener("click", async () => {
            currentMeet.MeetingInstance.leave();
            this.router.navigateByUrl('/');
        });

        this.endCall?.addEventListener("click", async () => {
            currentMeet.MeetingInstance.end();
            this.router.navigateByUrl('/');
        });
    }

    createLocalParticipantAudioVideo(LocalParticipantId) {
        const localParticipant = this.createVideoElement(LocalParticipantId);
        this.videoContainer.appendChild(localParticipant);
        return this.createAudioElement(LocalParticipantId);
    }

    // creating video element
    createVideoElement(pId) {
        let videoFrame = document.createElement("div");
        videoFrame.classList.add("video-frame");
        videoFrame.setAttribute("id", `vf-${pId}`);

        //create video
        let videoElement = document.createElement("video");
        videoElement.classList.add("video");
        videoElement.setAttribute("id", `v-${pId}`);
        videoElement.setAttribute("autoplay", "true");
        videoFrame.appendChild(videoElement);

        return videoFrame;
    }

    // creating audio element
    createAudioElement(pId) {
        let audioElement = document.createElement("audio");
        audioElement.setAttribute("autoPlay", "false");
        audioElement.setAttribute("playsInline", "true");
        audioElement.setAttribute("controls", "false");
        audioElement.setAttribute("id", `a-${pId}`);
        return audioElement;
    }

    //setting up tracks
    setTrack(stream, audioElement, participant, isLocal) {
        if (stream.kind == "video") {
            if (isLocal) {
                if (this.videoCamOff?.style)
                    this.videoCamOff.style.display = "none";
                if (this.videoCamOn?.style)
                    this.videoCamOn.style.display = "inline-block";
            }
            const mediaStream = new MediaStream();
            mediaStream.addTrack(stream.track);
            let videoElm: any = document.getElementById(`v-${participant.id}`);
            if (videoElm) {
                videoElm.srcObject = mediaStream;
                videoElm.play().catch((error) => console.error("videoElem.current.play() failed", error));
                participant.setViewPort(videoElm.offsetWidth, videoElm.offsetHeight);
            }
        }
        if (stream.kind == "audio") {
            if (isLocal) {
                if (this.micOff?.style)
                    this.micOff.style.display = "none";
                if (this.micOn?.style)
                    this.micOn.style.display = "inline-block";
                return;
            }
            const mediaStream = new MediaStream();
            mediaStream.addTrack(stream.track);
            if (audioElement) {
                audioElement.srcObject = mediaStream;
                audioElement.play().catch((error) => console.error("audioElem.play() failed", error));
            }
        }
    }

    toggleControls() {

        if (this.micEnable) {
            console.log("micEnable True");
            if (this.micOn?.style)
                this.micOn.style.display = "inline-block";

            if (this.micOff?.style)
                this.micOff.style.display = "none";
        } else {
            console.log("micEnable False");
            if (this.micOn?.style)
                this.micOn.style.display = "none";
            if (this.micOff?.style)
                this.micOff.style.display = "inline-block";
        }

        if (this.webCamEnable) {
            if (this.videoCamOn?.style)
                this.videoCamOn.style.display = "inline-block";
            if (this.videoCamOff?.style)
                this.videoCamOff.style.display = "none";
        } else {
            if (this.videoCamOn?.style)
                this.videoCamOn.style.display = "none";
            if (this.videoCamOff?.style)
                this.videoCamOff.style.display = "inline-block";
        }
    }

    setVideoMediaStream = async (): Promise<any> => {
        return new Promise(async (resolve) => {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false,
            }).catch((err) => { return null; })
            if (mediaStream) {
                this.joinPageVideoStream = mediaStream;
                if (this.joinPageWebcam) {
                    this.joinPageWebcam["srcObject"] = mediaStream;
                    this.joinPageWebcam.play();
                }
            }
            this.webCamEnable = true;
            return resolve(true);
        })

    }

    getParticipants = async (Participants: any[]): Promise<any> => {
        return new Promise((resolve) => {
            Participants = Participants?.filter((x) => {
                if (x.type === "Stage") {
                    return x.role !== SYS_Role.Admin && x.role !== SYS_Role.Audience
                } else {
                    return (x.role !== SYS_Role.Admin)
                }
            });
            return resolve(Participants);
        })

    }

    public switchMeeting = async (objParticipant: any, meetingId: string) => {
        const objMeetUser = objParticipant.type === "BackStage" ? this.BackStageMeeting.MeetingInstance.participants.get(objParticipant.pId) : this.StageMeeting.MeetingInstance.participants.get(objParticipant.pId);

        objMeetUser.switchTo({
            meetingId: meetingId,
            token: this.Auth_Token,
            payload: JSON.stringify({
                DisplayName: objParticipant.displayName,
                Role: objParticipant.role,
                MeetingType: objParticipant.type == "Stage" ? "BackStage" : "Stage"
            })
        })
    }

    public waitUntil = async (delay = 2000) => {
        return new Promise((resolve) => { setTimeout(() => { return resolve(0) }, delay); })
    }
}

export interface JoinInfo {
    MeetingType: "Stage" | "BackStage";
    DisplayName: string;
    Role: string;
    isFirst?: boolean;
}

export interface MeetingInfo {
    MeetingInstance: Meeting;
    MeetingId: string;
    AllParticipants: any[];
    Participants: any[];
}

export const SYS_Role = {
    Admin: "Admin",
    StageUser: "Stage User",
    Audience: "Audience"
}