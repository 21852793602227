<div class="grid-page flex-container" id="gridPpage" style="position: relative">
    <div class="row" style="height: 70px; width: 100%; border-bottom: 1px solid grey; margin: 0;">
        <div class="col-12">
            <div style="margin-top: 13px; position: static;float: right;">
                <!--call end-->
                <div class="btn-group">
                    <button type="button" class="btn btn-danger" id="leaveCall">
                        <span class="material-icons"> call_end </span>
                    </button>
                    <!-- <div class="dropdown-menu" style="background-color: #212032;color: white;margin-left: -80px;">
                        <a class="dropdown-item" id="leaveCall">Leave Meeting</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<video id="videoScreenShare" style="display: none;"></video>


<div class="row" id="videoContainer" style="margin: 0;"></div>