import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VideoSDK } from '@videosdk.live/js-sdk';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  // meet: any;
  TOKEN = environment.Auth_Token;
  token = ''
  AUTH_URL = environment.AUTH_URL;

  joinMeetingName = '';
  joinMeetingId = '';
  stageMeetingId = '';

  createMeetingFlag = 0;
  joinMeetingFlag = 0;
  meeting: any = "";
  micEnable = true;
  webCamEnable = true;
  joinPageVideoStream = null;
  joinPageWebcam: any;
  totalParticipants = 0;
  participants: any = [];
  remoteParticipantId = "";
  localParticipant: any = '';
  localParticipantAudio;
  screenShareOn = false;
  validateMeetingIdAnswer = '';


  micOn: any;
  micOff: any;
  videoCamOff: any;
  videoCamOn: any;
  videoContainer: any;
  participantsList: any;
  btnStartRecording: any;
  btnStopRecording: any;

  videoScreenShare: any;
  btnScreenShare: any;
  contentRaiseHand: any;
  copy_meeting_id: any;
  copy_stage_meeting_id: any;
  btnRaiseHand: any;
  leaveCall: any;
  endCall: any;

  meetParticipant: any[] = [];
  constructor(
    private router: Router,
  ) { }

  async joinMeeting(newMeeting) {
    this.tokenGeneration();
    let joinMeetingName = this.joinMeetingName || "Admin";
    let meetingId = this.joinMeetingId;

    if (meetingId == '' && !newMeeting) {
      return alert("Please Provide a meetingId");
    }

    if (newMeeting) {

    } else {
      const userRole = localStorage.getItem('userRole');
      if (userRole == 'audience') {
        this.router.navigateByUrl('/stage-meeting/' + meetingId)
      } else {
        this.router.navigateByUrl('/meeting/' + meetingId)
      }
      this.startMeeting(this.token, meetingId, joinMeetingName);
      return
    }

    //create New Meeting
    //get new meeting if new meeting requested;
    if (newMeeting && this.TOKEN != "") {
      const url = `${environment.API_BASE_URL}/api/meetings`;
      const options = {
        method: "POST",
        headers: { Authorization: this.token, "Content-Type": "application/json" },
      };

      const { meetingId } = await fetch(url, options)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
      this.joinMeetingId = meetingId;

      this.router.navigateByUrl('/meeting/' + this.joinMeetingId)
      setTimeout(() => {
        this.toggleControls();
      }, 1000);

      localStorage.setItem('backStageMeetingId', this.joinMeetingId);
      this.startMeeting(this.token, meetingId, joinMeetingName);
    } else if (newMeeting && this.TOKEN == "") {
      const options = {
        method: "POST",
        headers: {
          Authorization: this.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: this.token }),
      };

      meetingId = await fetch(environment.AUTH_URL + "/createMeeting", options).then(
        async (result) => {
          const { meetingId } = await result.json();
          return meetingId;
        }
      );
      // document.getElementById("meetingid")['value'] = meetingId;
      this.joinMeetingId = meetingId;
      this.router.navigateByUrl('/meeting/' + this.joinMeetingId)
      setTimeout(() => {
        this.toggleControls();
      }, 1000);

      this.startMeeting(this.token, meetingId, joinMeetingName);
    }
  }

  async tokenGeneration() {
    if (this.TOKEN != "") {
      this.token = this.TOKEN;
    } else if (this.AUTH_URL != "") {
      this.token = await window
        .fetch(this.AUTH_URL + "/generateJWTToken")
        .then(async (response) => {
          const { token } = await response.json();
          return token;
        })
        .catch(async (e) => {
          console.log(await e);
          return;
        });
    } else if (this.AUTH_URL == "" && this.TOKEN == "") {
      alert("Set Your configuration details first ");
      window.location.href = "/";
      // window.location.reload();
    } else {
      alert("Check Your configuration once ");
      window.location.href = "/";
      // window.location.reload();
    }
  }

  toggleControls() {
    if (this.micEnable) {
      console.log("micEnable True");
      if (this.micOn?.style)
        this.micOn.style.display = "inline-block";

      if (this.micOff?.style)
        this.micOff.style.display = "none";
    } else {
      console.log("micEnable False");
      if (this.micOn?.style)
        this.micOn.style.display = "none";
      if (this.micOff?.style)
        this.micOff.style.display = "inline-block";
    }

    if (this.webCamEnable) {
      console.log("webCamEnable True");
      if (this.videoCamOn?.style)
        this.videoCamOn.style.display = "inline-block";
      if (this.videoCamOff?.style)
        this.videoCamOff.style.display = "none";
    } else {
      console.log("webCamEnable False");
      if (this.videoCamOn?.style)
        this.videoCamOn.style.display = "none";
      if (this.videoCamOff?.style)
        this.videoCamOff.style.display = "inline-block";
    }
  }

  async startMeeting(token, meetingId, name) {
    if (this.joinPageVideoStream !== null) {
      const tracks = this.joinPageVideoStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.joinPageVideoStream = null;
      this.joinPageWebcam.srcObject = null;
    }

    // Meeting config
    VideoSDK.config(token);

    let customVideoTrack = await VideoSDK.createCameraVideoTrack({
      optimizationMode: "motion",
      encoderConfig: "h540p_w960p",
      facingMode: "environment",
    });

    let customAudioTrack = await VideoSDK.createMicrophoneAudioTrack({
      encoderConfig: "high_quality",
      noiseConfig: {
        echoCancellation: true,
        autoGainControl: true,
        noiseSuppression: true,
      },
    });

    // Meeting Init
    this.meeting = VideoSDK.initMeeting({
      meetingId: meetingId, // required
      token: token,
      name: name, // required
      micEnabled: true, // optional, default: true
      webcamEnabled: true, // optional, default: true
      maxResolution: "hd", // optional, default: "hd"
      customCameraVideoTrack: customVideoTrack,
      customMicrophoneAudioTrack: customAudioTrack,
    });

    setTimeout(() => {
      this.toggleControls();
    }, 1000);
    this.participants = this.meeting.participants;
    // Meeting Join
    this.meeting.join();

    //create Local Participant
    if (localStorage.getItem('userRole') != 'audience') {
      this.createLocalParticipant();
    }

    //add yourself in participant list
    if (this.totalParticipants != 0)
      this.addParticipantToList({
        id: this.meeting.localParticipant.id,
        displayName: "You",
      });

    // Setting local participant stream
    this.meeting.localParticipant.on("stream-enabled", (stream) => {
      this.setTrack(
        stream,
        this.localParticipantAudio,
        this.meeting.localParticipant,
        true
      );
    });

    this.meeting.localParticipant.on("stream-disabled", (stream) => {
      if (stream.kind == "video") {
        this.videoCamOn.style.display = "none";
        this.videoCamOff.style.display = "inline-block";
      }
      if (stream.kind == "audio") {
        this.micOn.style.display = "none";
        this.micOff.style.display = "inline-block";
      }
    });

    this.meeting.on("meeting-joined", (res) => {
      console.log('res: ', res);
      this.meeting.pubSub.subscribe("CHAT", (data) => {
        let { message, senderId, senderName, timestamp } = data;
        const chatBox = document.getElementById("chatArea");
        const chatTemplate = `
            <div style="margin-bottom: 10px; ${this.meeting.localParticipant.id == senderId && "text-align : right"
          }">
              <span style="font-size:12px;">${senderName}</span>
              <div style="margin-top:5px">
                <span style="background:${this.meeting.localParticipant.id == senderId ? "grey" : "crimson"
          };color:white;padding:5px;border-radius:8px">${message}<span>
              </div>
            </div>
            `;
        chatBox.insertAdjacentHTML("beforeend", chatTemplate);
      });
    });

    // Other participants
    this.meeting.on("participant-joined", (participant) => {
      console.log('participant: ', participant);
      this.totalParticipants++;
      let videoElement = this.createVideoElement(participant.id);
      console.log("Video Element Created");
      // @ts-ignore
      let resizeObserver = new ResizeObserver(() => {
        participant.setViewPort(
          videoElement.offsetWidth,
          videoElement.offsetHeight
        );
      });
      resizeObserver.observe(videoElement);
      let audioElement = this.createAudioElement(participant.id);
      this.remoteParticipantId = participant.id;


      this.meetParticipant.push({
        meetid: this.meeting.id,
        pId: participant.id,
        displayName: participant.displayName,
        mode: participant.mode
      })

      participant.on("stream-enabled", (stream) => {
        // this.setTrack(stream, audioElement, participant, (isLocal = false));
        this.setTrack(stream, audioElement, participant, false);
      });
      this.videoContainer.appendChild(videoElement);
      console.log("Video Element Appended");
      this.videoContainer.appendChild(audioElement);
      // let division = document.createElement("div");
      // division.innerHTML=participant.displayName;
      // division.className='participant-displayName';
      // this.videoContainer.appendChild(division)
      this.addParticipantToList(participant);
    });

    // participants left
    this.meeting.on("participant-left", (participant) => {
      this.totalParticipants--;
      let vElement = document.getElementById(`v-${participant.id}`);
      vElement.parentNode.removeChild(vElement);

      let aElement = document.getElementById(`a-${participant.id}`);
      aElement.parentNode.removeChild(aElement);
      //remove it from participant list participantId;
      document.getElementById(`p-${participant.id}`).remove();


      this.meetParticipant = this.meetParticipant.filter(element => {
        if (element.id != participant.id) return element;
      });
     

    });
    //chat message event
    // meeting.on("chat-message", (chatEvent) => {
    //   const { senderId, text, timestamp, senderName } = chatEvent;
    //   const parsedText = JSON.parse(text);

    //   if (parsedText?.type == "chat") {
    //     const chatBox = document.getElementById("chatArea");
    //     const chatTemplate = `
    //     <div style="margin-bottom: 10px; ${
    //       meeting.localParticipant.id == senderId && "text-align : right"
    //     }">
    //       <span style="font-size:12px;">${senderName}</span>
    //       <div style="margin-top:5px">
    //         <span style="background:${
    //           meeting.localParticipant.id == senderId ? "grey" : "crimson"
    //         };color:white;padding:5px;border-radius:8px">${
    //       parsedText.message
    //     }<span>
    //       </div>
    //     </div>
    //     `;
    //     chatBox.insertAdjacentHTML("beforeend", chatTemplate);
    //   }
    // });

    // //video state changed
    // meeting.on("video-state-changed", (videoEvent) => {
    //   const { status, link, currentTime } = videoEvent;

    //   switch (status) {
    //     case "started":
    //       videoPlayback.setAttribute("src", link);
    //       videoPlayback.play();
    //       break;
    //     case "stopped":
    //       console.log("stopped");
    //       videoPlayback.removeAttribute("src");
    //       videoPlayback.pause();
    //       videoPlayback.load();
    //       break;
    //     case "resumed":
    //       videoPlayback.play();
    //       break;
    //     case "paused":
    //       videoPlayback.currentTime = currentTime;
    //       videoPlayback.pause();
    //       break;

    //     case "seeked":
    //       break;

    //     default:
    //       break;
    //   }
    // });

    //recording events
    this.meeting.on("recording-started", () => {
      // console.log("RECORDING STARTED EVENT");
      this.btnStartRecording.style.display = "none";
      this.btnStopRecording.style.display = "inline-block";
    });
    this.meeting.on("recording-stopped", () => {
      // console.log("RECORDING STOPPED EVENT");
      this.btnStartRecording.style.display = "inline-block";
      this.btnStopRecording.style.display = "none";
    });

    this.meeting.on("presenter-changed", (presenterId) => {
      if (presenterId) {
        // console.log(presenterId);
        //videoScreenShare.style.display = "inline-block";
      } else {
        // console.log(presenterId);
        this.videoScreenShare.removeAttribute("src");
        this.videoScreenShare.pause();
        this.videoScreenShare.load();
        this.videoScreenShare.style.display = "none";

        this.btnScreenShare.style.color = "white";
        this.screenShareOn = false;
        // console.log(`screen share on : ${this.screenShareOn}`);
      }
    });


    // This event will be emitted to participant P2 of Meeting A.
    this.meeting.on("switch-meeting", ({ meetingId, payload, token }) => {
      this.meeting.leave();
      this.router.navigateByUrl('/');
      setTimeout(() => {
        this.router.navigateByUrl('/meeting/' + meetingId);
        this.startMeeting(this.token, meetingId, payload);
      }, 1000);
    });

    //add DOM Events
    this.addDomEvents();
  }

  createLocalParticipant() {
    this.totalParticipants++;
    this.localParticipant = this.createVideoElement(this.meeting.localParticipant.id);
    this.localParticipantAudio = this.createAudioElement(this.meeting.localParticipant.id);
    this.videoContainer.appendChild(this.localParticipant);
  }

  // creating video element
  createVideoElement(pId) {
    let division;
    division = document.createElement("div");
    division.setAttribute("id", "video-frame-container");
    division.className = `v-${pId}`;
    let videoElement = document.createElement("video");
    videoElement.classList.add("video-frame");
    videoElement.setAttribute("id", `v-${pId}`);
    videoElement.setAttribute("playsinline", "true");
    //videoElement.setAttribute('height', '300');
    videoElement.setAttribute("width", "300");
    division.appendChild(videoElement);
    return videoElement;
  }

  // creating audio element
  createAudioElement(pId) {
    let audioElement = document.createElement("audio");
    audioElement.setAttribute("autoPlay", "false");
    audioElement.setAttribute("playsInline", "true");
    audioElement.setAttribute("controls", "false");
    audioElement.setAttribute("id", `a-${pId}`);
    return audioElement;
  }

  addParticipantToList({ id, displayName }) {
    let participantTemplate = document.createElement("div");
    participantTemplate.className = "row";
    participantTemplate.style.padding = "4px";
    participantTemplate.style.marginTop = "1px";
    participantTemplate.style.marginLeft = "7px";
    participantTemplate.style.marginRight = "7px";
    participantTemplate.style.borderRadius = "3px";
    participantTemplate.style.border = "1px solid rgb(61, 60, 78)";
    participantTemplate.style.backgroundColor = "rgb(61, 60, 78)";
    participantTemplate.setAttribute("id", `p-${id}`);

    //icon
    let colIcon = document.createElement("div");
    colIcon.className = "col-2";
    colIcon.innerHTML = "Icon";
    participantTemplate.appendChild(colIcon);

    //name
    let content = document.createElement("div");
    colIcon.className = "col-3";
    colIcon.innerHTML = `${displayName}`;
    participantTemplate.appendChild(content);
    // participants.push({ id, displayName });

    let switchButtom = document.createElement("div");
    switchButtom.className = "col-3";
    // switchButtom.innerHTML = 'switch';
    if (localStorage.getItem('userRole') == 'admin' && this.meeting.localParticipant.id != id) {
      let button = document.createElement("input");
      button.type = "button";
      button.value = "switch";
      button.setAttribute("id", id);
      let self = this;
      button.onclick = function () { self.switchUser(id); return false; };
      switchButtom.appendChild(button);
      participantTemplate.appendChild(switchButtom);
    }

    console.log(this.participants);

    this.participantsList.appendChild(participantTemplate);
    this.participantsList.appendChild(document.createElement("br"));
  }

  //setting up tracks
  setTrack(stream, audioElement, participant, isLocal) {
    if (stream.kind == "video") {
      if (isLocal) {
        if (this.videoCamOff?.style)
          this.videoCamOff.style.display = "none";
        if (this.videoCamOn?.style)
          this.videoCamOn.style.display = "inline-block";
      }
      const mediaStream = new MediaStream();
      mediaStream.addTrack(stream.track);
      let videoElm: any = document.getElementById(`v-${participant.id}`);
      videoElm.srcObject = mediaStream;
      videoElm
        .play()
        .catch((error) =>
          console.error("videoElem.current.play() failed", error)
        );
      participant.setViewPort(videoElm.offsetWidth, videoElm.offsetHeight);
    }
    if (stream.kind == "audio") {
      if (isLocal) {
        if (this.micOff?.style)
          this.micOff.style.display = "none";
        if (this.micOn?.style)
          this.micOn.style.display = "inline-block";
        return;
      }
      const mediaStream = new MediaStream();
      mediaStream.addTrack(stream.track);
      audioElement.srcObject = mediaStream;
      audioElement
        .play()
        .catch((error) => console.error("audioElem.play() failed", error));
    }
    if (stream.kind == "share" && !isLocal) {
      this.screenShareOn = true;
      const mediaStream = new MediaStream();
      mediaStream.addTrack(stream.track);
      this.videoScreenShare.srcObject = mediaStream;
      this.videoScreenShare
        .play()
        .catch((error) =>
          console.error("videoElem.current.play() failed", error)
        );
      this.videoScreenShare.style.display = "inline-block";
      this.btnScreenShare.style.color = "grey";
    }
  }


  //add button events once meeting is created
  addDomEvents() {
    // mic button event listener
    this.micOn?.addEventListener("click", () => {
      this.meeting.muteMic();
    });

    this.micOff?.addEventListener("click", () => {
      this.meeting.unmuteMic();
    });

    this.videoCamOn?.addEventListener("click", async () => {
      this.meeting.disableWebcam();
    });

    this.videoCamOff?.addEventListener("click", async () => {
      this.meeting.enableWebcam();
    });
    if (localStorage.getItem('userRole') != 'audience') {
      // screen share button event listener
      this.btnScreenShare?.addEventListener("click", async () => {
        if (this.btnScreenShare?.style?.color == "grey") {
          this.meeting.disableScreenShare();
        } else {
          this.meeting.enableScreenShare();
        }
      });
    }

    //raise hand event
    this.btnRaiseHand?.addEventListener("click", () => {
      let participantId = this.localParticipant.className;
      if (this.contentRaiseHand && this.contentRaiseHand?.style) {
        if (participantId.split("-")[1] == this.meeting.localParticipant.id) {
          this.contentRaiseHand.innerHTML = "You Have Raised Your Hand";
        } else {
          this.contentRaiseHand.innerHTML = `<b>${this.remoteParticipantId}</b> Have Raised Their Hand`;
        }
        this.contentRaiseHand.style.display = "inline-block";
        setTimeout(() => {
          this.contentRaiseHand.style.display = "none";
        }, 2000);
      }
    });

    //send chat message button
    // this.btnSend.addEventListener("click", async () => {
    //   const message = document.getElementById("txtChat")['value'];
    //   console.log("publish : ", message);
    //   document.getElementById("txtChat")['value'] = "";
    //   this.meeting.pubSub
    //     .publish("CHAT", message, { persist: true })
    //     .then((res) => console.log(`response of publish : ${res}`))
    //     .catch((err) => console.log(`error of publish : ${err}`));
    //   // meeting.sendChatMessage(JSON.stringify({ type: "chat", message }));
    // });

    // //leave Meeting Button
    this.leaveCall?.addEventListener("click", async () => {
      this.participants = new Map(this.meeting.participants);
      this.meeting.leave();
      this.router.navigateByUrl('/')
      localStorage.removeItem('userRole');
      // window.location.reload();
      // document.getElementById("home-page").style.display = "flex";
    });

    if (localStorage.getItem('userRole') == 'admin') {
      //end meeting button
      this.endCall?.addEventListener("click", async () => {
        this.meeting.end();
        this.router.navigateByUrl('/');
        localStorage.removeItem('userRole');
        // window.location.reload();
      });
    }

    // //startVideo button events [playing VIDEO.MP4]
    // startVideoBtn.addEventListener("click", async () => {
    //   meeting.startVideo({ link: "/video.mp4" });
    // });

    // //end video playback
    // stopVideoBtn.addEventListener("click", async () => {
    //   meeting.stopVideo();
    // });
    // //resume paused video
    // resumeVideoBtn.addEventListener("click", async () => {
    //   meeting.resumeVideo();
    // });
    // //pause playing video
    // pauseVideoBtn.addEventListener("click", async () => {
    //   meeting.pauseVideo({ currentTime: videoPlayback.currentTime });
    // });
    // //seek playing video
    // seekVideoBtn.addEventListener("click", async () => {
    //   meeting.seekVideo({ currentTime: 40 });
    // });
    if (localStorage.getItem('userRole') == 'admin') {
      // //startRecording
      this.btnStartRecording?.addEventListener("click", async () => {
        this.meeting.startRecording();
      });
      // //Stop Recording
      this.btnStopRecording?.addEventListener("click", async () => {
        this.meeting.stopRecording();
      });
    }
  }

  async toggleWebCam() {
    if (this.joinPageVideoStream) {
      this.joinPageWebcam.style.backgroundColor = "black";
      this.joinPageWebcam.srcObject = null;
      document.getElementById("camButton").style.backgroundColor = "red";
      document.getElementById("offCamera").style.display = "inline-block";
      document.getElementById("onCamera").style.display = "none";
      this.webCamEnable = false;
      const tracks = this.joinPageVideoStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.joinPageVideoStream = null;
    } else {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then((stream) => {
          this.joinPageVideoStream = stream;
          this.joinPageWebcam.srcObject = stream;
          this.joinPageWebcam.play();
        });
      document.getElementById("camButton").style.backgroundColor = "#DCDCDC";
      document.getElementById("offCamera").style.display = "none";
      document.getElementById("onCamera").style.display = "inline-block";
      this.webCamEnable = true;
    }
  }

  async toggleMic() {
    if (this.micEnable) {
      document.getElementById("micButton").style.backgroundColor = "red";
      document.getElementById("muteMic").style.display = "inline-block";
      document.getElementById("unmuteMic").style.display = "none";
      this.micEnable = false;
    } else {
      this.micEnable = true;
      document.getElementById("micButton").style.backgroundColor = "#DCDCDC";
      document.getElementById("muteMic").style.display = "none";
      document.getElementById("unmuteMic").style.display = "inline-block";
    }
  }

  copyMeetingCode() {
    this.copy_meeting_id.select();
    document.execCommand("copy");
  }

  //open participant wrapper
  openParticipantWrapper() {
    document.getElementById("participants").style.width = "350px";
    document.getElementById("gridPpage").style.marginRight = "350px";
    document.getElementById("ParticipantsCloseBtn").style.visibility = "visible";
    document.getElementById("totalParticipants").style.visibility = "visible";
    document.getElementById(
      "totalParticipants"
    ).innerHTML = `Participants (${this.totalParticipants})`;
  }

  openChatWrapper() {
    document.getElementById("chatModule").style.width = "350px";
    document.getElementById("gridPpage").style.marginRight = "350px";
    document.getElementById("chatCloseBtn").style.visibility = "visible";
    document.getElementById("chatHeading").style.visibility = "visible";
    document.getElementById("btnSend").style.display = "inline-block";
  }

  closeParticipantWrapper() {
    document.getElementById("participants").style.width = "0";
    document.getElementById("gridPpage").style.marginRight = "0";
    document.getElementById("ParticipantsCloseBtn").style.visibility = "hidden";
    document.getElementById("totalParticipants").style.visibility = "hidden";
  }

  closeChatWrapper() {
    document.getElementById("chatModule").style.width = "0";
    document.getElementById("gridPpage").style.marginRight = "0";
    document.getElementById("chatCloseBtn").style.visibility = "hidden";
    document.getElementById("btnSend").style.display = "none";
  }

  async validateMeeting() {
    this.tokenGeneration();
    let meetingId = this.joinMeetingId;
    if (this.token != "") {
      const url = `${environment.API_BASE_URL}/api/meetings/${meetingId}`;

      const options = {
        method: "POST",
        headers: { Authorization: this.token },
      };

      const result = await fetch(url, options)
        .then((response) => response.json()) //result will have meeting id
        .catch((error) => {
          console.error("error", error);
          alert("Invalid Meeting Id");
          window.location.href = "/";
          return;
        });
      if (result.meetingId === meetingId) {
        navigator.mediaDevices
          .getUserMedia({
            video: true,
            audio: false,
          })
          .then((stream) => {
            this.joinPageVideoStream = stream;
            this.joinPageWebcam['srcObject'] = stream;
            this.joinPageWebcam.play();
          });
        this.router.navigateByUrl('/Joining');
        return meetingId;
      }
    } else {
      this.validateMeetingIdAnswer = await fetch(
        this.AUTH_URL + "/validatemeeting/" + meetingId,
        {
          method: "POST",
          headers: {
            Authorization: this.token,
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (result) => {
          const { meetingId } = await result.json();
          if (meetingId == undefined) {
            return alert("Invalid Meeting ID ");
          } else {
            navigator.mediaDevices
              .getUserMedia({
                video: true,
                audio: false,
              })
              .then((stream) => {
                this.joinPageVideoStream = stream;
                this.joinPageWebcam.srcObject = stream;
                this.joinPageWebcam.play();
              });
            // document.getElementById("joinPage").style.display = "flex";
            // document.getElementById("home-page").style.display = "none";
            this.router.navigateByUrl('/Joining');
            return meetingId;
          }
        })
        .catch(async (e) => {
          // alert("Meeting ID Invalid", await e);
          window.location.href = "/";
          return;
        });

    }
  }

  async createNewMeeting() {
    // let joinMeetingName = this.joinMeetingName || "Admin";
    const url = `${environment.API_BASE_URL}/api/meetings`;
    const options = {
      method: "POST",
      headers: { Authorization: this.token, "Content-Type": "application/json" },
    };

    const { meetingId } = await fetch(url, options)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
    this.stageMeetingId = meetingId;
    localStorage.setItem('stageMeetingId', this.stageMeetingId);
    console.warn('this.stageMeetingId: ', this.stageMeetingId);
    window.open('/', "_blank");
  }

  copyStageMeetingCode() {
    this.copy_stage_meeting_id.select();
    document.execCommand("copy");
  }

  switchUser(id) {
    // Get participant of meeting A
    const Participant = this.meeting.participants.get(id);
    const token = environment.Auth_Token;
    const payload = Participant.displayName;
    let meetingId = '';
    if (localStorage.getItem('currentMeetingId')) {
      meetingId = localStorage.getItem('currentMeetingId') == localStorage.getItem('stageMeetingId') ? localStorage.getItem('backStageMeetingId') : localStorage.getItem('stageMeetingId')
    } else {
      meetingId = this.stageMeetingId;
    }
    localStorage.setItem('currentMeetingId', meetingId)

    Participant.switchTo({ meetingId, token, payload });
    // };
  }
}
