import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminMeetComponent } from './components/admin-meet/admin-meet.component';
import { BackStageMeetingComponent } from './components/back-stage-meeting/back-stage-meeting.component';
import { HomeComponent } from './components/home/home.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { StageMeetingComponent } from './components/stage-meeting/stage-meeting.component';
// import { JoiningPageComponent } from './components/joining-page/joining-page.component';
// import { VideoCallPageComponent } from './components/video-call-page/video-call-page.component';
// import { ViwerPageComponent } from './components/viwer-page/viwer-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'loading',
    component: LoadingPageComponent
  },
  {
    path: 'admin-stage-meet/:isFromIframe/:stageMeetingId',
    component: AdminMeetComponent
  },
  {
    path: 'admin-meet/:backStageMeetingId/:stageMeetingId',
    component: AdminMeetComponent
  },
  {
    path: 'stage-meet/:meetingId/:userName',
    component: StageMeetingComponent
  },
  {
    path: 'backStage-meet/:meetingId/:userName/:mtype',
    component: BackStageMeetingComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
