import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminMeetingService, SYS_Role } from 'src/app/service/admin-meet.service';

@Component({
  selector: 'app-stage-meeting',
  templateUrl: './stage-meeting.component.html',
  styleUrls: ['./stage-meeting.component.css']
})
export class StageMeetingComponent implements OnInit {
  userObj: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    private adminMeetingService: AdminMeetingService,
    private router: Router
  ) {

    this.activatedRoute.params.subscribe(params => {
      this.joinMeeting(params.meetingId, params.userName)
    });
  }
  
  ngOnInit(): void {
    this.adminMeetingService.videoContainer = document.getElementById("videoContainer");
    this.adminMeetingService.leaveCall = document.getElementById("leaveCall");
  }

  ngAfterViewInit(): void {
   
  }

  async joinMeeting(meetingId, userName) {
    // const smeetId = "xt36-b2qr-24l5"
    const smeetId = await this.adminMeetingService.validateMeeting(meetingId);
    await this.adminMeetingService.startMeeting(smeetId, {
      DisplayName: userName,
      Role: SYS_Role.Audience,
      MeetingType: 'Stage'
    });
  }

}
