import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMeetingService, SYS_Role } from 'src/app/service/admin-meet.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css']
})
export class LoadingPageComponent implements OnInit {
  userObj: any = {};
  url = ''
  constructor(
    private router: Router,
    private adminMeetingService: AdminMeetingService,
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.userObj = this.router.getCurrentNavigation().extras.state;
    } else {
      this.router.navigate(['/']);
    }
  }

  async ngOnInit(): Promise<void> {

    if (this.userObj.userRole == 'Admin') {
      await this.createAdminMeet();
    } else if (this.userObj.userRole == 'BackStage') {
      this.url = '/backStage-meet/' + this.userObj.meetingId + '/' + this.userObj.userName+"/BackStage";
    } else {
      this.url = '/stage-meet/' + this.userObj.meetingId + '/' + this.userObj.userName;
    }

    // setTimeout(() => {
    this.router.navigate([this.url])
    // }, 3000);
  }

  createAdminMeet = async () => {
    const meetId = await this.adminMeetingService.createMeeting();
    const smeetId = await this.adminMeetingService.createMeeting();
    this.url = '/admin-meet/' + meetId + '/' + smeetId;

  }

}
