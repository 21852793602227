import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminMeetingService, SYS_Role } from 'src/app/service/admin-meet.service';

@Component({
  selector: 'app-back-stage-meeting',
  templateUrl: './back-stage-meeting.component.html',
  styleUrls: ['./back-stage-meeting.component.css']
})
export class BackStageMeetingComponent implements OnInit {
  userObj: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    private adminMeetingService: AdminMeetingService,
    private router: Router
  ) {

    this.activatedRoute.params.subscribe(params => {
      this.joinMeeting(params.meetingId, params.userName, params.mtype)
    });
  }

  ngOnInit(): void {
    this.adminMeetingService.videoContainer = document.getElementById("videoContainer");
    this.adminMeetingService.micOn = document.getElementById("main-pg-unmute-mic");
    this.adminMeetingService.micOff = document.getElementById("main-pg-mute-mic");
    this.adminMeetingService.videoCamOff = document.getElementById("main-pg-cam-off");
    this.adminMeetingService.videoCamOn = document.getElementById("main-pg-cam-on");
    this.adminMeetingService.leaveCall = document.getElementById("leaveCall");
  }

  async joinMeeting(meetingId, userName, mtype) {
    // const smeetId = "xt36-b2qr-24l5"
    const smeetId = await this.adminMeetingService.validateMeeting(meetingId);
    await this.adminMeetingService.startMeeting(smeetId, {
      DisplayName: userName,
      Role: SYS_Role.StageUser,
      MeetingType: mtype
    });

    if (mtype === "BackStage")
      this.adminMeetingService.BackStageMeeting?.MeetingInstance?.disableWebcam();
    else
      this.adminMeetingService.StageMeeting?.MeetingInstance?.disableWebcam();

    setTimeout(() => {
      if (mtype === "BackStage")
        this.adminMeetingService.BackStageMeeting?.MeetingInstance?.enableWebcam();
      else
        this.adminMeetingService.StageMeeting?.MeetingInstance?.enableWebcam();
    }, 3000);
  }
}
