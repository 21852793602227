<div *ngIf="!LoadOnlyIframe">
    <!-- [hidden]="!adminMeetingService.IsMeetStart" -->
    <div>
        <div class="meet-dom">
            <div class="text-white">
                <div style="display: flex;">Stage :&nbsp;<span
                        id="stgMeeting-span">{{adminMeetingService.StageMeeting.MeetingId}}</span>
                    <button class="copy-btn" (click)="copyMeetId('stgMeeting-span')">
                        <span class="material-icons"> content_copy </span>
                    </button>
                </div>
                <div style="display: flex;">BackStage :&nbsp;<span
                        id="backStgMeeting-span">{{adminMeetingService.BackStageMeeting.MeetingId}}</span>
                    <button class="copy-btn" (click)="copyMeetId('backStgMeeting-span')">
                        <span class="material-icons"> content_copy </span>
                    </button>
                </div>
            </div>

            <!-- Stage Metting -->
            <div>
                <iframe scrolling="no" #stageMeetIFrame></iframe>
            </div>

            <!-- Back Stage Meeting -->
            <div class="row backstage-meet">
                <div class="col-lg-2 col-md-4 col-sm-6 backstage-user"
                    *ngFor="let backstageUser of adminMeetingService.BackStageMeeting.Participants">
                    <span style="color:white">{{backstageUser.displayName}}</span>
                    <div id="user-{{backstageUser.pId}}" style="position: relative;height: 130px;">
                        <button class="switch-btn" (click)="switchUser(backstageUser)">Switch</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Stage Meeting Content -->
<div class="stage-meet" *ngIf="LoadOnlyIframe">
    <div class="row">
        <div class="col-lg-2 col-md-4 col-sm-6 backstage-user"
            *ngFor="let stageUser of adminMeetingService.StageMeeting.Participants">
            <span style="color:white">{{stageUser.displayName}}</span>
            <div id="user-{{stageUser.pId}}" style="position: relative; height: 130px;">
                <button class="switch-btn" (click)="switchUser(stageUser)">Switch</button>
            </div>
        </div>
    </div>
</div>