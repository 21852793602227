// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_KEY: '4704f38e-721e-479c-8ced-9b77858cd75b',
  SECRET: '070308828c3749a9176460ca45f64b9e88ad43160829ff762d4a087ffa2542a8',
  API_BASE_URL:'https://api.videosdk.live',
  BASE_URL:'http://videosdk2.disolutions.net',
  AUTH_URL:'https://api.videosdk.live',///'http://localhost:4200/',
  Auth_Token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI0NzA0ZjM4ZS03MjFlLTQ3OWMtOGNlZC05Yjc3ODU4Y2Q3NWIiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY3Njg4ODA0NiwiZXhwIjoxNjc5NDgwMDQ2fQ.K6UXDoU0L8GQUPAtnpTlBKw4wDZ48Yrfq6GFZdbVqLA",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
