<div class="container main-bg">
  <div style="color: gray;margin-top: 50px;">
    <div class="row align-items-center">
      <div class="col-sm-8">
        <!-- video container -->
        <div class="join-left" style="
          position: relative;
        ">
          <div class="video-view">
            <video class="video" id="joinCam" style="
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              transform: rotate('90');
              object-fit: cover;
              box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
                0 6px 6px rgba(0, 0, 0, 0.23);
            "></video>
            <div class="input-group mb-3 video-content">
              <button style="border-radius: 20px; height: 50px; width: 50px" id="camButton" (click)="toggleWebCam()">
                <i class="bi bi-camera-video-fill" style="color: black; font-size: 21px" id="onCamera"></i>
                <i class="bi bi-camera-video-off-fill" style="color: black; font-size: 21px; display: none"
                  id="offCamera"></i>
              </button>
              <button style="
                border-radius: 20px;
                height: 50px;
                width: 50px;
                margin-left: 5px;
              " id="micButton" (click)="toggleMic()">
                <i class="bi bi-mic-mute-fill" style="color: black; font-size: 21px; display: inline-block"
                  id="muteMic"></i>
                <i class="bi bi-mic-fill" style="color: black; font-size: 21px; display: none" id="unmuteMic"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="">
          <!-- info container  -->
          <div class="join-right">
            <h1 class="text-center" style="color: grey; margin-bottom: 30px;">Video Meeting</h1>
            <div class="input-group mb-3 d-flex align-items-center">
              <div class="input-group-prepend person-icn">
                <span class="material-icons"> person </span>
              </div>
              <input type="text" class="form-control" placeholder="Username" [(ngModel)]="userName">
            </div>
            <div class="input-group mb-3">
              <select class="form-control col-12" [(ngModel)]="currentUserRole">
                <option value="" disabled>select role</option>
                <option value="Admin">Admin</option>
                <option value="BackStage">Back Stage</option>
                <option value="Audience">Audience</option>
              </select>
            </div>
            <div class="input-group mb-3 d-flex align-items-center"
              *ngIf="currentUserRole!=='Admin' && currentUserRole!==''">
              <input type="text" class="form-control" placeholder="Meeting ID" [(ngModel)]="MeetingId">
            </div>
            <div class="input-group mb-3">
              <button type="button" class="btn btn-primary" (click)="joinMeeting()">
                {{currentUserRole!=='Admin'?'Join Meeting':'Create Meeting'}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>