import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminMeetingService, SYS_Role } from 'src/app/service/admin-meet.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-meet',
  templateUrl: './admin-meet.component.html',
  styleUrls: ['./admin-meet.component.css']
})
export class AdminMeetComponent implements OnInit {

  @ViewChild('stageMeetIFrame') iframe: ElementRef;
  LoadOnlyIframe: Boolean = false;
  iframeParent: any = null;
  constructor(
    public adminMeetingService: AdminMeetingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params.backStageMeetingId && params.stageMeetingId) {
        const smeetId = await this.adminMeetingService.validateMeeting(params.stageMeetingId);
        const meetId = await this.adminMeetingService.validateMeeting(params.backStageMeetingId);
        if (meetId && smeetId) {
          adminMeetingService.StageMeeting.MeetingId = params.stageMeetingId;
          const stageMeetingurl = environment.BASE_URL + '/admin-stage-meet/1/' + params.stageMeetingId;
          this.iframe.nativeElement.setAttribute('src', stageMeetingurl)

          await this.adminMeetingService.startMeeting(params.backStageMeetingId, {
            DisplayName: "Admin",
            Role: SYS_Role.Admin,
            MeetingType: "BackStage",
            isFirst: true
          });
        } else {
          this.router.navigate(['/']);
        }
      } else if (params.isFromIframe === "1" && params.stageMeetingId) {
        this.LoadOnlyIframe = true;

        // const smeetId = await this.adminMeetingService.createMeeting();
        const smeetId = params.stageMeetingId;
        // const smeetId = "lkyx-1efo-xdtg"
        await this.adminMeetingService.startMeeting(smeetId, {
          DisplayName: "Admin",
          Role: SYS_Role.Admin,
          MeetingType: 'Stage'
        });
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit(): void {
    if (!this.LoadOnlyIframe) {
      // this.adminMeetingService.joinPageWebcam = document.getElementById("joinCam");
    }


    if (!this.LoadOnlyIframe) {
      //MAIN PAGE Message EVENT (Backstage Meet Running)
      window.addEventListener("message", (event) => {
        if (event.data?.from === "Stage") {
          this.iframe.nativeElement.contentWindow.postMessage({ from: "switchUserToBackStage", Participant: event.data.Participant, SwitchMeetId: this.adminMeetingService.BackStageMeeting.MeetingId }, "*")
        } else if (event.data?.from === "switchUserToStage") {
          this.adminMeetingService.switchMeeting(event.data?.Participant, event.data?.SwitchMeetId);
        }
      });
    } else {
      //Iframe Message EVENT (Stage Meet Running)
      window.addEventListener("message", (event) => {
        if (event.data?.from === "BackStage") {
          window.parent.postMessage({ from: "switchUserToStage", Participant: event.data.Participant, SwitchMeetId: this.adminMeetingService.StageMeeting.MeetingId }, "*")
        } else if (event.data?.from === "switchUserToBackStage") {
          this.adminMeetingService.switchMeeting(event.data?.Participant, event.data?.SwitchMeetId);
        }
      });
    }

  }

  switchUser(objParticipant) {
    //current user in backstage meet
    if (objParticipant.type === "BackStage") {
      //switch to stage
      this.iframe.nativeElement.contentWindow.postMessage({ from: objParticipant.type, Participant: objParticipant }, "*")
    }//current user in stage meet
    else if (objParticipant.type === "Stage") {
      //switch to backstage
      window.parent.postMessage({ from: objParticipant.type, Participant: objParticipant }, "*");
    }
  }

  copyMeetId(idElement) {
    const meetidDOM: any = document.getElementById(idElement);
    var textArea = document.createElement("textarea");
    textArea.value = meetidDOM.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  }
}
