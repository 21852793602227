<div class="grid-page flex-container" id="gridPpage" style="position: relative">
    <div class="row" style="height: 70px; width: 100%; border-bottom: 1px solid grey; margin: 0;">
        <div class="col-12">
            <div class="col-9" style="margin-top: 13px; position: static; float: left">
                <!-- main page toggle mic-->
                <div class="btn-group" id="main-pg-mute-mic" style="display: inline-block">
                    <button type="button" class="btn btn-outline-light ms-1" aria-haspopup="true" aria-expanded="false">
                        <span class="material-icons"> mic_off </span>
                    </button>
                </div>
                <div class="btn-group" id="main-pg-unmute-mic" style="display: none">
                    <button type="button" class="btn btn-outline-light ms-1" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="material-icons"> mic </span>
                    </button>
                </div>
                <!--main page toggle web-cam-->
                <div class="btn-group" id="main-pg-cam-off" style="display: inline-block">
                    <button type="button" class="btn btn-outline-light ms-1" aria-haspopup="true" aria-expanded="false">
                        <span class="material-icons"> videocam_off</span>
                    </button>
                </div>
                <div class="btn-group" id="main-pg-cam-on" style="display: none">
                    <button type="button" class="btn btn-outline-light ms-1" aria-haspopup="true" aria-expanded="false"
                        id="videoCamOn">
                        <span class="material-icons"> videocam </span>
                    </button>
                </div>
            </div>
            <div style="margin-top: 13px; position: static;float: right;">
                <!--call end-->
                <div class="btn-group">
                    <button type="button" class="btn btn-danger" id="leaveCall">
                        <span class="material-icons"> call_end </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<video id="videoScreenShare" style="display: none;"></video>
<div id="videoContainer"></div>