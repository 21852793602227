import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AdminMeetComponent } from './components/admin-meet/admin-meet.component';
import { FormsModule } from '@angular/forms';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { StageMeetingComponent } from './components/stage-meeting/stage-meeting.component';
import { BackStageMeetingComponent } from './components/back-stage-meeting/back-stage-meeting.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoadingPageComponent,
    AdminMeetComponent,
    StageMeetingComponent,
    BackStageMeetingComponent
    // JoiningPageComponent,
    // VideoCallPageComponent,
    // ViwerPageComponent,



    // AppComponent,
    // HomeComponent,
    // AdminComponent,
    // StageMeetingComponent,
    // BackStageMeetingComponent,
    // LoadingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
