import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMeetingService } from 'src/app/service/admin-meet.service';
import { BaseService } from 'src/app/service/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentUserRole = '';
  userName = '';
  MeetingId = '';

  constructor(
    private baseService: BaseService,
    private router: Router,
    private adminMeetingService: AdminMeetingService,

  ) { }

  ngOnInit(): void {
    this.baseService.toggleWebCam();
  }

  createAdminMeet = async () => {

    const meetId = await this.adminMeetingService.createMeeting();
    // const meetId = "v9zg-5u9n-2jp1";
    await this.adminMeetingService.startMeeting(meetId, {
      DisplayName: "Admin",
      Role: "Admin",
      MeetingType: "BackStage",
      isFirst: true
    });

    this.router.navigate(['/admin-meet'])

  }

  ngAfterViewInit(): void {
    this.baseService.joinPageWebcam = document.getElementById("joinCam");
  }

  toggleWebCam() {
    this.baseService.toggleWebCam();
  }
  toggleMic() {
    this.baseService.toggleMic();
  }

  joinMeeting() {
    if (this.currentUserRole !== '') {
      let obj = {
        userRole: this.currentUserRole,
        userName: this.userName,
        meetingId: this.MeetingId
      }
      this.router.navigate(['loading'], { state: obj });
    } else {
      alert("Please select your role");
    }
  }
  
}
